<template>
  <Toast />
  <ConfirmDialog />

  <Dialog v-model:visible="closeCard" :style="{ width: '50vw' }" :modal="true">
    <Fieldset legend="Cierre de ticket">
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12">
          <label for="sat">Retroalimentacion</label>
          <Textarea :autoResize="false" :rows="8" v-model="editCard.feedback" />
        </div>
        <div class="p-field p-col-12">
          <Button label="Cerrar" class="p-button-primary" @click="closeTicket" />
        </div>
      </div>
    </Fieldset>
  </Dialog>

  <Dialog v-model:visible="editTicket" :style="{ width: '50vw' }" :modal="true">
    <TicketUI
      :view="viewTicket"
      @updated="updatedCard"
      @deleted="deletedCard"
      :id="editCard.id"
    />
  </Dialog>

  <Fieldset :toggleable="true" legend="Parametros">
    <Toolbar class="p-mb-4">
      <template v-slot:left>
        <Button
          label="Listar"
          icon="pi pi-list"
          class="p-button-info p-mr-2"
          @click="list"
        />
        <Button
          label="Recargar"
          icon="pi pi-refresh"
          class="p-button-primary p-mr-2"
          @click="refresh"
        />
      </template>
    </Toolbar>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4">
        <label>Cliente</label>
        <Dropdown
          v-model="entity.id_tm_customer"
          :options="customers"
          :loading="loadingInputs"
          optionLabel="name"
          optionValue="id"
          @keypress.enter="list"
        />
      </div>
      <div class="p-field p-col-4">
        <label>Proyecto</label>
        <Dropdown
          v-model="entity.id_tm_project"
          :options="projectsByCustomer"
          :disabled="loadingIentitynputs"
          optionLabel="name"
          optionValue="id"
          @keypress.enter="list"
        />
      </div>
       <div class="p-field p-col-4">
                <label>Cerrados</label>
                <Dropdown
                  v-model="entity.closed"
                  :options="[
                    {
                      id: true,
                      name: 'SI',
                    },
                    {
                      id: false,
                      name: 'NO',
                    },
                  ]"
                  optionLabel="name"
                  optionValue="id"
                  @keypress.enter="list"
                />
              </div>
    </div>
  </Fieldset>
  <br />
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />

    <div
      class="p-col-4"
      v-for="status in statuses"
      :class="'panel-' + status.name.toLowerCase()"
      :key="status.value"
    >
      <Panel toggleable>
        <template #header>
          {{ status.name }} - ({{ ticketing[status.id].length }})
        </template>
        <Card
          v-for="ticket in ticketing[status.id]"
          :key="ticket.id"
          :style="{ backgroundColor: getColor(ticket.status), marginTop: '1rem' }"
        >
          <template #title style="color: black">
            <b v-if="ticket.is_closed"> [CERRADO] </b><b>{{ ticket.subject }} </b>
          </template>
          <template #subtitle>
            {{ ticket.customer_name }} / {{ ticket.project_name }}</template
          >
          <template #content>
            <p>
              Creado el <b>{{ format(ticket.created) }}</b> para
              <b>{{ format(ticket.expiration_date) }}</b>
            </p>
            <p>
              Asignado a <b>{{ ticket.user_name }}</b>
            </p>
            <p>
              {{ ticket.body }}
            </p>
          </template>
          <template #footer>
            <div>
              <Chip
                :label="ticket.ticket_tag_name"
                :style="getStyleChip(ticket.ticket_tag_color)"
                v-if="ticket.id_tm_ticket_tag"
              />
              <SplitButton
                style="padding-left: 1rem"
                label="Acciones"
                :model="getOptions(ticket)"
              ></SplitButton>
            </div>
          </template>
        </Card>
      </Panel>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { ErrorToast, fillObject } from "../../../utilities/General";
import { TMTicket } from "../../../models/tickets/TMTicket";
import TicketUI from "./Ticket.vue";
import { TMProject } from "../../../models/tickets/TMProject";
import { TMCustomer } from "../../../models/tickets/TMCustomer";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
export default {
  mixins: [Session],
  data() {
    return {
      editCard: new TMTicket(this.session),
      closeCard: false,
      entity: {
        id_tm_project: null,
        id_tm_customer: null,
        id_tm_user: null,
        closed: false
      },
      editTicket: false,
      viewTicket: false,
      entities: [],
      tickets: [],
      filters: {},
      loading: false,
      loadingInputs: false,
      projects: [],
      customers: [],
      options: [],
      statuses: [
        {
          value: 1,
          name: "REGISTRADO",
          id: "registrado",
        },
        {
          value: 2,
          name: "EN PROCESO",
          id: "proceso",
        },
        {
          value: 3,
          name: "TERMINADO",
          id: "terminado",
        },
      ],
    };
  },
  components: { Loader, TicketUI },
  computed: {
    ticketing() {
      return {
        registrado: this.tickets.filter((x) => x.status == 1),
        proceso: this.tickets.filter((x) => x.status == 2),
        terminado: this.tickets.filter((x) => x.status == 3),
      };
    },
  },
  methods: {
    updatedCard(card) {
      this.editCard = new TMTicket(this.session);
      let updatedTicket = this.tickets.find((x) => x.id == card.id);
      fillObject(updatedTicket, card);
      this.editTicket = false;
    },
    getOptions(card) {
      let options = [
        {
          label: "Ver",
          icon: "pi pi-eye",
          command: () => {
            this.editCard = card;
            this.editTicket = true;
            this.viewTicket = true;
          },
        },
        {
          label: "Editar",
          icon: "pi pi-pencil",
          command: () => {
            this.editCard = card;
            this.editTicket = true;
          },
        },
        {
          label: "Cerrar",
          icon: "pi pi-check-circle",
          command: () => {
            this.$confirm.require({
              message:
                "Esta seguro que desea cerrar el ticket #" +
                card.id +
                " [" +
                card.subject +
                "]?",
              header: "Confirmacion",
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                this.editCard = card;
                this.closeCard = true;
              },
              reject: () => {
                this.$toast.add(new ErrorToast("Accion cancelada"));
              },
            });
          },
        },
        {
          label: "Registrado",
          command: () => {
            this.update(1, card);
          },
        },
        {
          label: "En Proceso",
          command: () => {
            this.update(2, card);
          },
        },
        {
          label: "Terminar",
          command: () => {
            this.update(3, card);
          },
        },
        {
          label: "Cancelar",
          command: () => {
            this.$confirm.require({
              message:
                "Esta seguro que desea cancelar el ticket #" +
                card.id +
                " [" +
                card.subject +
                "]?",
              header: "Confirmacion",
              icon: "pi pi-exclamation-triangle",
              accept: () => {
                this.update(9, card);
              },
              reject: () => {
                this.$toast.add(new ErrorToast("Accion cancelada"));
              },
            });
          },
        },
      ];
      if (card.is_closed) {
        options = options.filter((x) => x.label != "Cerrar");
      }

      if (card.status == 1) {
        return options.filter((x) => x.label != "Registrado");
      } else if (card.status == 2) {
        return options.filter((x) => x.label != "En Proceso");
      } else if (card.status == 3) {
        return options.filter((x) => x.label != "Terminar");
      } else {
        return options;
      }
    },
    async closeTicket() {
      try {
        this.loading = true;
        let ticket = new TMTicket(this.session);
        ticket.id = this.editCard.id;
        ticket.feedback = this.editCard.feedback;
        await ticket.cerrar();
        let updatedTicket = this.tickets.find((x) => x.id == ticket.id);
        updatedTicket.is_closed = true;
        updatedTicket.feedback = ticket.feedback;
        this.$toast.add({
          severity: "success",
          summary: "Ticket",
          detail: "Se ha cerrado el ticket",
          life: 3000,
        });
        this.closeCard = false;
        this.editCard = new TMTicket(this.session);

        await ticket.enviarCorreoCerrado();
        this.$toast.add({
          severity: "success",
          summary: "Ticket",
          detail: "Se ha enviado el correo de cierre",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async update(status, card) {
      try {
        this.loading = true;
        let ticket = new TMTicket(this.session);
        ticket.id = card.id;
        ticket.status = status;
        await ticket.updateStatus();
        this.$toast.add({
          severity: "success",
          summary: "Actualizar",
          detail: "Informacion actualizada con exito",
          life: 3000,
        });
        card.status = status;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    format(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getColor(status) {
      if (status == 1) {
        return "#091b4a";
      } else if (status == 2) {
        return "#ad7109";
      } else if (status == 3) {
        return "#013d18";
      }
    },
    async list() {
      this.loading = true;
      try {
         this.entities = await new TMTicket(this.session).data({
          customer: this.entity.id_tm_customer,
          project: this.entity.id_tm_project,
          user: this.entity.id_tm_user,
          closed: this.entity.closed,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    getStyleChip(color) {
      return {
        background: "#" + color,
      };
    },
    async refresh() {
      this.loading = true;
      try {
        this.customers = await new TMCustomer().all();
        this.projects = await new TMProject().all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    editTicket(newValue) {
      if (!newValue) {
        this.viewTicket = false;
      }
    },
    async ["entity.id_tm_customer"](newValue) {
      this.entity.id_tm_project = null;
      if (newValue) {
        try {
          this.loadingInputs = true;
          this.projectsByCustomer = this.projects.filter(project => project.id_tm_customer == newValue)
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loadingInputs = false;
        }
      }
    },
  },
  async mounted() {
    await this.refresh();
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

::v-deep(.p-card) {
  .p-card-subtitle {
    color: #ffff;
  }
}
</style>
